var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VContainer',{staticClass:"reports-container",attrs:{"fluid":"","px-0":"","py-0":""}},[_c('VRow',[_c('VCol',{staticClass:"graph-area pb-0",attrs:{"xs":"12","md":"12"}},[_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('strong',{staticStyle:{"font-size":"24px"}},[_vm._v("Your Dashboards")]),_c('p',{staticClass:"dashboard-info"},[_vm._v(" It shows the main statistics that you chose in each dashboard, to see in detail all the statistics enter the same ")])]),_c('VCol',{staticClass:"d-flex flex-row-reverse",attrs:{"cols":"6"}},[_c('VBtn',{staticClass:"add-btn",attrs:{"fab":"","color":"#f0c013"},on:{"click":function($event){return _vm.navigateTo('report.dashboard')}}},[_c('VIcon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)],1)],1),(_vm.activity.isLoading)?_c('VRow',{attrs:{"row":"","fluid":"","justify-center":"","align-center":"","fill-height":""}},[_c('VProgressCircular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('VRow',{staticStyle:{"max-height":"400px","overflow-y":"scroll"}},_vm._l((_vm.pinnedDashboards),function(dashboard){return _c('VCol',{key:dashboard.id,staticClass:"pb-4",attrs:{"md":dashboard.pinned_report &&
              dashboard.pinned_report.config.cols == 4
              ? `12`
              : `6`}},[_c('VCard',{staticClass:"updates-section"},[_c('VCardText',{staticStyle:{"min-height":"170px"}},[(dashboard.pinned_report && dashboard.pinned_report.reporting_data_default.length > 0 && _vm.isSupportedChart(dashboard.pinned_report.chart_type.name))?_c(_vm.getChartComponentName(dashboard.pinned_report.chart_type.name),{ref:_vm.normalizeChartRef(dashboard.pinned_report.chart_type.name),refInFor:true,tag:"component",attrs:{"config":dashboard.pinned_report.config,"chart":dashboard.pinned_report,"report-data":dashboard.pinned_report.reporting_data_default}}):_c('VCol',[_c('VAlert',{staticClass:"pl-0 pr-0",attrs:{"icon":"warning"}},[_vm._v(" No reports found! ")])],1)],1),_c('VCardActions',{staticClass:"pt-0",staticStyle:{"height":"40px"}},[_c('strong',{staticClass:"chart-title text-truncate"},[_vm._v(_vm._s(dashboard.title))]),_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.navigateTo({
                    name: 'report.dashboard',
                    params: { id: dashboard.id },
                  })}}},[_c('VIcon',{attrs:{"color":"black"}},[_vm._v(" mdi-arrow-right-bold-circle-outline ")])],1),_c('VSpacer'),(
                  dashboard.pinned_report &&
                    dashboard.pinned_report.is_pinned_to_main_dashboard
                )?_c('VSwitch',{attrs:{"input-value":"true","loading":dashboard.id == _vm.unPinDashboardId && _vm.unPinLoader},on:{"change":function($event){return _vm.unpinReport($event, dashboard.id)}}}):_vm._e()],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }