<template>
  <VContainer
    fluid
    px-0
    py-0
    class="reports-container"
  >
    <VRow>
      <VCol
        xs="12"
        md="12"
        class="graph-area pb-0"
      >
        <VRow>
          <VCol cols="6">
            <strong style="font-size: 24px;">Your Dashboards</strong>
            <p class="dashboard-info">
              It shows the main statistics that you chose in each dashboard, to
              see in detail all the statistics enter the same
            </p>
          </VCol>
          <VCol
            cols="6"
            class="d-flex flex-row-reverse"
          >
            <VBtn
              fab
              color="#f0c013"
              class="add-btn"
              @click="navigateTo('report.dashboard')"
            >
              <VIcon color="white">
                mdi-plus
              </VIcon>
            </VBtn>
          </VCol>
        </VRow>
        <VRow
          v-if="activity.isLoading"
          row
          fluid
          justify-center
          align-center
          fill-height
        >
          <VProgressCircular
            indeterminate
            color="primary"
          />
        </VRow>
        <VRow
          v-else
          style="max-height: 400px; overflow-y: scroll;"
        >
          <VCol
            v-for="dashboard in pinnedDashboards"
            :key="dashboard.id"
            :md="
              dashboard.pinned_report &&
                dashboard.pinned_report.config.cols == 4
                ? `12`
                : `6`
            "
            class="pb-4"
          >
            <VCard class="updates-section">
              <VCardText style="min-height: 170px;">
                <component
                  v-if="dashboard.pinned_report && dashboard.pinned_report.reporting_data_default.length > 0 && isSupportedChart(dashboard.pinned_report.chart_type.name)"
                  :config="dashboard.pinned_report.config"
                  :chart="dashboard.pinned_report"
                  :report-data="dashboard.pinned_report.reporting_data_default"
                  :is="getChartComponentName(dashboard.pinned_report.chart_type.name)"
                  :ref="normalizeChartRef(dashboard.pinned_report.chart_type.name)"
                />
                <VCol v-else>
                  <VAlert
                    icon="warning"
                    class="pl-0 pr-0"
                  >
                    No reports found!
                  </VAlert>
                </VCol>
              </VCardText>
              <VCardActions
                class="pt-0"
                style="height: 40px;"
              >
                <strong class="chart-title text-truncate">{{
                  dashboard.title
                }}</strong>
                <VBtn
                  icon
                  @click="
                    navigateTo({
                      name: 'report.dashboard',
                      params: { id: dashboard.id },
                    })
                  "
                >
                  <VIcon color="black">
                    mdi-arrow-right-bold-circle-outline
                  </VIcon>
                </VBtn>
                <VSpacer />
                <VSwitch
                  input-value="true"
                  :loading="dashboard.id == unPinDashboardId && unPinLoader"
                  v-if="
                    dashboard.pinned_report &&
                      dashboard.pinned_report.is_pinned_to_main_dashboard
                  "
                  @change="unpinReport($event, dashboard.id)"
                />
              </VCardActions>
            </VCard>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
import SupportsCharts from "@/mixins/SupportsCharts";

export default {
  name: "ReportsIndex",
  mixins: [SearchMixin, SupportsCharts],
  data() {
    return {
      unPinLoader: false,
      unPinDashboardId: null,
    };
  },
  computed: {
    pinnedDashboards() {
      return this.config.pinned_dashboards || [];
    },
    pinnedStats() {
      return this.config.pinned_stats || [];
    },
  },
  methods: {
    ...mapActions({
      doGetMainDashboard: "report/getIndexConfig",
      doUnpinReportDashboard: "reportDashboard/unpin",
    }),
    onGetIndexConfig() {
      this.emitLoading();
      this.activity.isLoading = true;
      return this.doGetMainDashboard().then((config) => {
        this.emitLoadingDone();
        this.activity.isLoading = false;
        return config;
      });
    },
    unpinReport(status, id) {
      if (!status) {
        this.unPinLoader = true;
        this.unPinDashboardId = id;
        let params = {
          report_dashboard_id: id,
        };
        this.doUnpinReportDashboard(params)
          .then((result) => {
            this.unPinLoader = false;
            this.$bus.$emit(
              "notificationMessage",
              "Report unpinned successfully!"
            );
            this.onGetMainDashboard(id);
          })
          .catch((error) => {
            this.$log.debug("Unpin report dashboard error!", error);
          });
      }
    },
  },
};
</script>

<style lang="scss">
.updates-section {
  box-shadow: -0.5px 1.4px 6.1px 0.4px rgba(0, 0, 0, 0.03) !important;
}
.reports-container {
  max-width: 1400px;
  .stats-area {
    border-right: 1px solid #e2e0e0;
  }
  .graph-area {
    padding: 30px !important;
  }
  .add-btn {
    width: 37px;
    height: 37px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 #fcb27b;
  }
  .chartAreaWrapper {
    width: 100%;
    overflow-x: scroll;
  }
  .dashboard-info {
    font-size: 11px;
    opacity: 0.75;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.65);
  }
  .chart-title {
    font-size: 11px;
    font-weight: normal;
  }
}
</style>
